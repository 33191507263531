

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const validateAndParseUrl = (inputUrl: string): { isValid: boolean; parsedUrl: string | null } => {
    try {
        let parsedUrl = inputUrl

        // If the input URL doesn't start with 'http://' or 'https://', add 'https://' as a prefix.
        if (!/^https?:\/\//i.test(inputUrl)) {
            parsedUrl = `https://${inputUrl}`
        }

        const url = new URL(parsedUrl)

        // If the URL is successfully parsed, it is valid.
        return {
            isValid: true,
            parsedUrl: url.href,
        }
    } catch (error) {
        // If an error occurs during parsing, the URL is invalid.
        return {
            isValid: false,
            parsedUrl: null,
        }
    }
}