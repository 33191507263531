import {IStrapiEdge, IStrapiImage} from "./strapi";
import {IProjectplanRegel} from "./projectplan-regel";
import {IUser} from "./user";
import {Productgroep} from "./productgroep";

export interface IProjectplan extends IStrapiEdge<IProjectplanAttributes>{}

export interface IProjectplanAttributes {
    magentoKlantId: string
    naam: string
    slug: string
    status: ProjectplanStatus
    gratisVerzending: boolean
    prijsBtw: ProjectplanPrijzenBtw
    margeTotaal: number | null
    kortingTotaal: number | null
    vastePrijs: number | null
    projectplanRegels: {
        data: IProjectplanRegel[]
    }
    user: IUser
    subtotaalExclBtw: number
    subtotaalInclBtw: number
    subtotaalBtw: number
    productGroepen: {
        data: Productgroep[]
    }
    opmerkingen: IProjectplanOpmerking[]
    bijlages: {
        data: IStrapiImage[]
    }
    links: Link[]
}

export enum ProjectplanStatus {
    OPEN = 'open',
    BESTELD = 'besteld',
}

export enum ProjectplanPrijzenBtw {
    INCLUSIEF = 'inclusief',
    EXCLUSIEF = 'exclusief',
}

export interface IProjectplanOpmerking {
    id: string
    opmerking: string
    created: string
}

export interface Link {
    id: string
    url: string
}