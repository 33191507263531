import {CONTENT} from "../queries/content";
import {IContent} from "../../../../types/content";
import { apolloClientNoAuth} from "../../../../providers/AppContext";

export interface getContentApiResponse {
    contents: { data: IContent[] }
}

export const getContent = async () => {
    return apolloClientNoAuth.query<getContentApiResponse>({
        query: CONTENT,
    })
}
