import {useMutation} from "@tanstack/react-query";
import {useProjectplan} from "./useProjectplan";
import {strapi} from "../../lib";
import {useMemo} from "react";
import {ProjectplanStatus} from "../../types/projectplan";


export const useCart = () => {
    const {projectplan} = useProjectplan()
    const mayAddToCart = useMemo(() => {
        return (projectplan?.attributes?.projectplanRegels?.data &&
            (projectplan?.attributes?.projectplanRegels?.data?.length > 0) &&
            (projectplan?.attributes?.status !== ProjectplanStatus.BESTELD)) as boolean
    }, [projectplan])
    const {mutate, isPending: isAddToCartLoading} = useMutation(
        {
            mutationKey: ['addToCart', projectplan?.id],
            mutationFn: () => strapi.projectplan.addToCart(projectplan?.id as string),
            onSuccess: async () => {
                await window.refreshCart()
                window.location.href = '/checkout/cart/'
            },
        },
    )

    return {
        add: () => {
            mayAddToCart && mutate()
        },
        isAddToCartLoading,
        mayAddToCart,
    }
}