import {createContext, Dispatch, SetStateAction, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {IProjectplan} from "../../types/projectplan";
import {strapi} from "../../lib";
import {IProjectplanRegel} from "../../types/projectplan-regel";

export interface ProjectplanDetailContext {
    projectplan: IProjectplan | null
    setProjectplan: Dispatch<SetStateAction<IProjectplan>>
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    showInclBtw: boolean
    setShowInclBtw: Dispatch<SetStateAction<boolean>>
    isAdvancedLoading: boolean
    setProjectplanRegel: (projectplanRegel: IProjectplanRegel) => void
    refetch: () => void
}

export const ProjectplanDetailContext = createContext<ProjectplanDetailContext>({
    projectplan: null,
    setProjectplan: () => {
    },
    isLoading: false,
    setIsLoading: () => {
    },
    showInclBtw: true,
    setShowInclBtw: async () => {
    },
    isAdvancedLoading: false,
    setProjectplanRegel: () => {
    },
    refetch: () => {
    },
})

export const useProjectplanContext = (projectplanSlug: string) => {
    const [projectplan, _setProjectplan] = useState<IProjectplan | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showInclBtw, setShowInclBtw] = useState<boolean>(true)
    const [isAdvancedLoading, setIsAdvancedLoading] = useState<boolean>(true)

    useQuery({
        queryKey: ['projectplanBySlugInitial', projectplanSlug],
        queryFn: async () => {
            setIsLoading(() => true)
            const response = await strapi.projectplan.findBySlug(projectplanSlug, false)

            if (!projectplan) {
                setProjectplan(() => response.data.projectplanBySlug)
            }

            setIsLoading(false)

            return response
        },
        refetchOnWindowFocus: false,
    })

    const {refetch} = useQuery({
        queryKey: ['projectplanBySlug', projectplanSlug],
        queryFn: async () => {
            if (!projectplan) {
                setIsAdvancedLoading(() => true)
            }

            const response = await strapi.projectplan.findBySlug(projectplanSlug, true)

            setProjectplan(response.data.projectplanBySlug)
            setIsAdvancedLoading(false)

            return response
        },
        refetchOnWindowFocus: false,
    })

    const setProjectplanRegel = (projectplanRegel: IProjectplanRegel) => {
        const newProjectplanGroepen = projectplan?.attributes?.productGroepen?.data?.map(group => {
            return {
                ...group,
                attributes: {
                    ...group.attributes,
                    producten: {
                        data: group.attributes.producten.data.map(oldProjectplanRegel => {
                            if (oldProjectplanRegel.id !== projectplanRegel.id) {
                                return oldProjectplanRegel
                            }

                            return {
                                ...oldProjectplanRegel,
                                ...projectplanRegel,
                                attributes: {
                                    ...oldProjectplanRegel.attributes,
                                    ...projectplanRegel.attributes,
                                }
                            }
                        })
                    }
                }
            }
        })
        const newProjectplanRegels = projectplan?.attributes?.projectplanRegels?.data?.map(oldProjectplanRegel => {
            if (oldProjectplanRegel.id !== projectplanRegel.id) {
                return oldProjectplanRegel
            }

            return {
                ...oldProjectplanRegel,
                ...projectplanRegel,
                attributes: {
                    ...oldProjectplanRegel.attributes,
                    ...projectplanRegel.attributes,
                }
            }
        })

        setProjectplan((prevState) => ({
            ...prevState,
            attributes: {
                ...prevState?.attributes,
                productGroepen: {
                    data: newProjectplanGroepen,
                },
                projectplanRegels: {
                    data: newProjectplanRegels,
                },
            },
        } as IProjectplan))
    }

    /**
     * Proxy function to set projectplan
     * Allows for easy logging of changes
     *
     * @param arg
     */
    const setProjectplan = (arg: IProjectplan | ((prevState: IProjectplan) => IProjectplan)) => {
        _setProjectplan((prevState) => {
            return typeof arg === 'function' ? (arg as Function)(prevState) : arg
        })
    }


    return {
        ProjectplanDetailContext,
        values: {
            setIsLoading,
            isLoading,
            showInclBtw,
            setShowInclBtw,
            projectplan,
            setProjectplan: setProjectplan as Dispatch<SetStateAction<IProjectplan>>,
            isAdvancedLoading,
            setProjectplanRegel,
            refetch,
        },
    }
}