import {useState, useRef, useEffect} from "react"

type UseIsVisibleOptions = {
    initialIsVisible: boolean
    hideOnClickOutside?: boolean
}
const useIsVisible = <T extends HTMLElement = HTMLElement>({initialIsVisible, hideOnClickOutside = false}: UseIsVisibleOptions) => {
    const [isVisible, setIsVisible] = useState(initialIsVisible)
    const ref = useRef<T>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (hideOnClickOutside && ref.current && !ref.current.contains(event.target as Node)) {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)

        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        };
    }, [])

    return {ref, isVisible, setIsVisible}
}

export {useIsVisible}