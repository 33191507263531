import {gql} from "@apollo/client";

export const PROJECTPLANNEN_ALL = gql`
    query projectplannen {
        projectplans {
            data {
                id
                attributes {
                    naam
                    slug
                }
            }
        }
    }
`

export const PROJECTPLANNEN_FILTERED = gql`
    query projectplannen($status: String) {
        projectplans(filters: {
            status: {
                eq: $status
            }
        }) {
            data {
                id
                attributes {
                    naam
                    slug
                }
            }
        }
    }
`

export const PROJECTPLAN_BY_SLUG = gql`
    query projectplanBySlug($slug: String!) {
        projectplanBySlug(slug: $slug) {
            id
            attributes {
                naam
                slug
                status
                opmerkingen(sort: "id:ASC", pagination: {pageSize: 99}) {
                    id
                    opmerking
                    created
                }
                bijlages(sort: "id:ASC", pagination: {pageSize: 99}) {
                    data {
                        id
                        attributes {
                            name
                            url
                            mime
                            alternativeText
                            size
                        }
                    }
                }
                links {
                    id
                    ... on ComponentProjectplanLink {
                        url
                    }
                }
                subtotaalExclBtw
                subtotaalInclBtw
                subtotaalBtw
                productGroepen {
                    data {
                        id
                        attributes {
                            titel
                            producten {
                                data {
                                    id
                                    attributes {
                                        naam
                                        type
                                        magentoProductSku
                                        aantal
                                        url
                                        kortingPercentage
                                        afbeeldingUrl
                                        isSalable
                                        stockQty
                                        prijsExclBtw {
                                            inkoopPrijs
                                            consumentenPrijs
                                            subtotaal
                                        }
                                        prijsInclBtw {
                                            inkoopPrijs
                                            consumentenPrijs
                                            subtotaal
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                projectplanRegels {
                    data {
                        id
                        attributes {
                            naam
                            type
                            magentoProductSku
                            aantal
                            url
                            kortingPercentage
                            afbeeldingUrl
                            isSalable
                            stockQty
                            prijsExclBtw {
                                inkoopPrijs
                                consumentenPrijs
                                subtotaal
                            }
                            prijsInclBtw {
                                inkoopPrijs
                                consumentenPrijs
                                subtotaal
                            }
                        }
                    }
                }
            }
        }
    }
`

export const PROJECTPLAN_BY_SLUG_NO_MAGENTO_DATA = gql`
    query projectplanBySlugNoMagentoData($slug: String!) {
        projectplanBySlug(slug: $slug) {
            id
            attributes {
                naam
                slug
                status
                opmerkingen(sort: "id:ASC", pagination: {pageSize: 99}) {
                    id
                    opmerking
                    created
                }
                bijlages(sort: "id:ASC", pagination: {pageSize: 99}) {
                    data {
                        id
                        attributes {
                            name
                            url
                            mime
                            alternativeText
                            size
                        }
                    }
                }
                links {
                    id
                    ... on ComponentProjectplanLink {
                        url
                    }
                }
                productGroepen {
                    data {
                        id
                        attributes {
                            titel
                            producten {
                                data {
                                    id
                                    attributes {
                                        naam
                                        type
                                        magentoProductSku
                                        aantal
                                        kortingPercentage
                                    }
                                }
                            }
                        }
                    }
                }
                projectplanRegels {
                    data {
                        id
                        attributes {
                            naam
                            type
                            magentoProductSku
                            aantal
                            kortingPercentage
                        }
                    }
                }
            }
        }
    }
`

export const PROJECTPLAN_NEW = gql`
    mutation createProjectplan(
        $naam: String!

    ) {
        createProjectplan(data: {
            naam: $naam
            status: open
            gratisVerzending: false
            prijzenBtw: inclusief
        }) {
            data {
                id
                attributes {
                    naam
                    slug
                }
            }
        }
    }
`

export const PROJECTPLAN_UPDATE = gql`
    mutation updateProjectplan($id: ID!, $naam: String, $projectplanRegels: [ID]) {
        updateProjectplan(id: $id, data: {
            naam: $naam
            projectplanRegels: $projectplanRegels
        }) {
            data {
                id
            }
        }
    }
`

export const PROJECTPLAN_DELETE = gql`
    mutation deleteProjectplan($id: ID!) {
        deleteProjectplan(id: $id) {
            data {
                id
                attributes {
                    naam
                }
            }
        }
    }
`

export const PROJECTPLAN_REGEL_DELETE = gql`
    mutation deleteProjectplanRegel($id: ID!) {
        deleteProjectplanregel(id: $id) {
            data {
                id
            }
        }
    }
`

export const PROJECTPLAN_REGEL_UPDATE = gql`
    mutation updateProjectplanRegel($id: ID!, $aantal: Int, $productgroep: ID){
        updateProjectplanregel(id: $id, data: {
            aantal: $aantal
            productgroep: $productgroep
        }) {
            data {
                id
                attributes {
                    productgroep {
                        data {
                            id
                            attributes {
                                titel
                            }
                        }
                    }
                }
            }
        }
    }
`

export const PROJECTPLAN_REGEL_ADD = gql`
    mutation addProjectplanRegelToProjectplan(
        $projectplanId: ID!
        $sku: String!
        $aantal: Int!
    ) {
        addProjectplanRegelToProjectplan(
            projectplanId: $projectplanId
            sku: $sku
            aantal: $aantal
        ) {
            projectplan {
                id
                attributes {
                    naam
                }
            }
            projectplanRegel {
                id
                attributes {
                    naam
                }
            }
        }
    }
`

export const PROJECTPLAN_ADD_TO_CART = gql`
    mutation addProjectplanToCart($id: ID!) {
        addProjectplanToCart(id: $id) {
            id
            attributes {
                naam
                status
            }
        }
    }
`

export const PROJECTPLAN_REQUEST_CHECK = gql`
    mutation projectplanRequestCheck($id: ID!) {
        projectplanRequestCheck(id: $id) {
            data {
                id
            }
        }
    }
`

export const PROJECTPLAN_OPMERKING_ADD = gql`
    mutation addOpmerkingToProjectplan($projectplanId: ID!, $opmerking: String!) {
        addOpmerkingToProjectplan(projectplanId: $projectplanId, opmerking: $opmerking) {
            data {
                id
                attributes {
                    naam
                    opmerkingen(sort: "id:ASC", pagination: {pageSize: 99}) {
                        id
                        opmerking
                        created
                    }
                }
            }
        }
    }
`

export const PROJECTPLAN_LINK_UPSERT = gql`
    mutation upsertProjectplanLink($projectplanId: ID!, $linkId: ID, $url: String!) {
        upsertProjectplanLink(projectplanId: $projectplanId, linkId: $linkId, url: $url) {
            id
            url
        }
    }
`

export const PROJECTPLAN_LINK_DELETE = gql`
    mutation deleteProjectplanLink($projectplanId: ID!, $linkId: ID!) {
        deleteProjectplanLink(projectplanId: $projectplanId, linkId: $linkId) {
            id
            url
        }
    }
`