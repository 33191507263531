import React from "react";
import {TransitionProps} from "@mui/material/transitions";
import {AlertColor, Fade} from "@mui/material";
import Snackbar, {SlideTransition, SnackbarProps} from "../../../components/feedback/Snackbar";

export interface useSnackbarOptions {
    severity: AlertColor
    autoHideDuration: number
}

export const useSnackbar = (config: useSnackbarOptions) => {
    const [state, setState] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<TransitionProps & {
            children: React.ReactElement<any, any>;
        }>;
    }>({
        open: false,
        Transition: Fade,
    })

    const triggerSnackbar =
        (
            Transition: React.ComponentType<TransitionProps & {
                children: React.ReactElement<any, any>;
            }>,
        ) => {
            setState(prevState => ({
                ...prevState,
                open: true,
            }))
        }

    const closeSnackbar = () => {
        setState(prevState => ({
            ...prevState,
            open: false,
        }))
    }

    const options: SnackbarProps = {
        ...config,
        open: state.open,
        transition: state.Transition,
        close: closeSnackbar,
    }

    return {
        Snackbar,
        options,
        triggerSnackbar: () => triggerSnackbar(SlideTransition),
        state,
    }
}