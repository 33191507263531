import React from "react";
import {createRoot} from 'react-dom/client';
import {addHours} from "date-fns";
import {strapi, utils} from "./lib";
import "./styles/styles.scss"
import {IMagentoUser} from "./types/magento";
import {createPortal} from "react-dom";

const interval = setInterval(() => {
    if (typeof window.isCustomerLoggedIn !== 'undefined' && typeof window.getCurrentCustomer !== 'undefined') {
        init()
        clearInterval(interval)
    }
}, 250)

const init = async () => {
    const isCustomerLoggedIn = window.isCustomerLoggedIn()
    const user = (isCustomerLoggedIn ? window.getCurrentCustomer() : null) as IMagentoUser | null

    if (!utils.localStorage.has('token') ||
        (new Date(utils.localStorage.get('expiry')) < new Date()) ||
        (user?.email && (user?.email !== utils.localStorage.get('user')?.email))
    ) {
        const token = user?.email ?
            await strapi.auth.generateToken(user.email, user.userType, user.storeViewId) :
            null

        utils.localStorage.set('user', user)
        if (user) {
            token?.data?.customerToken?.token ?
                utils.localStorage.set('token', token.data.customerToken.token) : utils.localStorage.remove('token')
            token?.data?.customerToken?.token ?
                utils.localStorage.set('expiry', addHours(new Date(), 1).toISOString()) :
                utils.localStorage.set('expiry', (new Date()).toISOString())
        }
    }

    if (!isCustomerLoggedIn) {
        utils.localStorage.remove('user')
    }

    if (process.env.NODE_ENV === 'development') {
        console.log({
            isCustomerLoggedIn,
            user: utils.localStorage.get('user'),
            token: utils.localStorage.get('token'),
        })
    }

    renderApp(user)
}

const renderApp = (user: IMagentoUser | null) => {
    const rootElement = document.getElementById('projectplannen-app')
    if (!rootElement) {
        return
    }

    const App = React.lazy(() => import('./_app'))
    const Projectplannen = renderProjectplannenApp()
    const AddToProjectplanButtons = renderAddToProjectplan()

    createRoot(rootElement).render(
        <React.StrictMode>
            <App user={user}>
                {Projectplannen}
                {AddToProjectplanButtons}
            </App>
        </React.StrictMode>
    )
}

const renderProjectplannenApp = () => {
    const rootElement = document.getElementById('projectplannen-root')
    if (!rootElement) {
        return
    }

    const Projectplannen = React.lazy(() => import('./_projectplannen'))

    return createPortal(
        <Projectplannen/>,
        rootElement,
    )
}

const renderAddToProjectplan = () => {
    const elements = document.querySelectorAll('.add-to-projectplan')

    if (!elements || elements.length === 0) {
        return
    }

    const AddToProjectplan = React.lazy(() => import('./components/add-to-projectplan/AddToProjectplan'))
    let components: React.ReactPortal[] = []

    elements.forEach(el => {
        const sku = el.getAttribute('data-sku')
        const qtyEl = document.querySelector(el.getAttribute('data-qty-field') || '') as HTMLInputElement
        const url = el.getAttribute('data-product-url')

        if (!sku || (!url && !qtyEl)) {
            return
        }

        components.push(
            createPortal(
                <AddToProjectplan
                    sku={sku}
                    qtyEl={qtyEl}
                    url={url}
                />,
                el,
            )
        )
    })

    return components
}

window.addAddToProjectplanButton = (id: string) => {
    const el = document.getElementById(id)
    if (!el) {
        return
    }

    const sku = el.getAttribute('data-sku')
    const qtyEl = document.querySelector(el.getAttribute('data-qty-field') || '') as HTMLInputElement
    const url = el.getAttribute('data-product-url')

    if (!sku || (!url && !qtyEl)) {
        return
    }

    const App = React.lazy(() => import('./_app'))
    const AddToProjectplan = React.lazy(() => import('./components/add-to-projectplan/AddToProjectplan'))

    createRoot(el).render(
       <App user={utils.localStorage.get('user')}>
           <AddToProjectplan
               sku={sku}
               qtyEl={qtyEl}
               url={url}
           />
       </App>
    )
}