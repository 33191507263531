import {apolloClientNoAuth} from "../../../../providers/AppContext";
import {CUSTOMER_TOKEN} from "../queries/auth";
import {IUser, UserType} from "../../../../types/user";

export type GenerateCustomerTokenResponse = {
    customerToken: {
        token: string
        user: IUser
    }
}
export const generateCustomerToken = async (email: string, type: UserType, storeViewId: string) => {
    return apolloClientNoAuth.mutate<GenerateCustomerTokenResponse>({
        mutation: CUSTOMER_TOKEN,
        variables: {
            email,
            type,
            storeViewId,
        },
    })
}