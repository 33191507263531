import React from "react";
import i18n from 'i18next';
import {I18nextProvider, initReactI18next} from "react-i18next";
import moment from 'moment';
import resourcesToBackend from 'i18next-resources-to-backend'
import ChainedBackend from 'i18next-chained-backend'
import strapi from "../lib/strapi";
import {flatten, unflatten} from "flat";
import {HasChildren} from "../types/components";



i18n
    // pass the i18n instance to react-i18next.
    .use(ChainedBackend)
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            backends: [
                resourcesToBackend(async (language, namespace, callback) => {
                    if (namespace !== 'translation') {
                        return
                    }

                    try {
                        const [resources, contents] = await Promise.all([ // @ts-ignore
                            await import(`./${language}/common.json`),
                            strapi.content.findMany()
                        ])
                        const resourcesFlat: any = flatten(resources)
                        contents.data.contents.data.forEach(data => {
                            resourcesFlat[data.attributes.naam] = data.attributes.inhoud
                        })

                        callback(null, unflatten(
                            resourcesFlat,
                            {
                                object: true,
                            },
                        ))
                    } catch (e: any) {
                        callback(e, null)
                    }
                })
            ]
        },
        //resources,
        lng: 'nl',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            formatSeparator: ',',
            format: function (value, formatting) {
                if (value instanceof Date) return moment(value).format(formatting);
                return value.toString();
            }
        }
    });



export const LangProvider: React.FC<HasChildren> = ({children}) => {
    return (
        <I18nextProvider i18n={i18n}>
            {children}
        </I18nextProvider>
    )
}