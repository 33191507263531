import {createContext, useEffect, useState} from "react";
import {utils} from "../../lib";
import {IMagentoUser} from "../../types/magento";
import {UserType} from '../../types/user'

export interface SessionContextType extends Session {
    setSession: (session: Session) => void
    setIsAuthenticated: (isAuthenticated: boolean) => void
    setUser: (user: User) => void
}

export interface Session {
    isAuthenticated: boolean
    user: User | null
}

export interface User {
    email: string
    userType: UserType
}

export const SessionContext = createContext<SessionContextType>({
    isAuthenticated: false,
    user: null,
    setSession: () => {
    },
    setIsAuthenticated: () => {
    },
    setUser: () => {
    },
})

export const useSessionContext = (user: IMagentoUser | null) => {
    const [session, setSession] = useState<Session>({
        isAuthenticated: !!user?.email,
        user: user?.email ? {
            email: user.email,
            userType: user.userType,
        } : null,
    })

    const setIsAuthenticated = (isAuthenticated: boolean) => {
        setSession(prevState => ({
            ...prevState,
            isAuthenticated,
        }))
    }

    const setUser = (user: User) => {
        setSession(prevState => ({
            ...prevState,
            user,
        }))
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const user = window.getCurrentCustomer()

            if (user?.email === session.user?.email) {
                return
            }

            utils.localStorage.set('user', user)
            setSession(() => ({
                isAuthenticated: !!user?.email,
                user: user?.email ? {
                    email: user.email,
                    userType: user.userType as UserType,
                } : null,
            }))
        }, session?.isAuthenticated ? 15000 : 1000 )

        return () => {
            clearInterval(interval)
        }
    }, [session?.isAuthenticated])

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            console.log(session)
        }
    }, [session])

    return {
        SessionContext,
        values: {
            user: session.user,
            isAuthenticated: session.isAuthenticated,
            setSession,
            setIsAuthenticated,
            setUser,
        },
    }
}