import {createContext, useState} from "react";
import {AlertColor} from "@mui/material";
import {SnackbarOrigin} from "@mui/material/Snackbar/Snackbar";


export interface NotificationsContextType {
    notifications: Notification[]
    addNotification: (notification: Notification) => void
    removeNotification: (notification: Notification) => void
}

export interface Notification {
    id: string
    message: string
    severity: AlertColor
    autoHideDuration: number
    anchorOrigin?: SnackbarOrigin
}

export const NotificationsContext = createContext<NotificationsContextType>({
    notifications: [],
    addNotification: () => {},
    removeNotification: () => {},
})

export const useNotificationsContext = () => {
    const [notifications, setNotifications] = useState<Notification[]>([])

    const addNotification = (notification: Notification) => {
        setNotifications((prev) => [...prev, {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
            },
            ...notification,
        }])
    }
    const removeNotification = (notification: Notification) => {
        setNotifications((prev) => prev.filter((n) => n.id !== notification.id))
    }

    return {
        NotificationsContext,
        values: {
            notifications,
            addNotification,
            removeNotification,
        },
    }
}