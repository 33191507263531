import {useTranslation} from "react-i18next";
import {useMutation} from "@tanstack/react-query";
import {IProjectplanRegel} from "../../types/projectplan-regel";
import {useProjectplan} from "../projectplan/useProjectplan";
import {strapi, utils} from "../../lib";
import {useNotifications} from "../notifications/useNotifications";
import {Productgroep} from "../../types/productgroep";


export const useProjectplanRegelDelete = (projectplanRegel: IProjectplanRegel) => {
    const {t} = useTranslation('translation', {keyPrefix: 'projectplan-regel.delete'})
    const {setProjectplan, projectplan} = useProjectplan()
    const {addNotification} = useNotifications()
    const removeProjectplanRegelFromState = (id: string) => {
        if (!projectplan) return

        const projectplanRegels: Productgroep[] = projectplan.attributes.productGroepen.data.map((productGroep) => {
            return {
                ...productGroep,
                attributes: {
                    ...productGroep.attributes,
                    producten: {
                        data: productGroep.attributes.producten.data.filter((product) => product.id !== id)
                    }
                }
            }
        })

        setProjectplan((prev) => utils.object.deepMerge(prev, {
            attributes: {
                productGroepen: {
                    data: projectplanRegels,
                }
            }
        }))
    }
    const {mutate, isPending: isDeleteProjectplanRegelLoading} = useMutation(
        {
            mutationKey: ['deleteProjectplanRegel', projectplanRegel.id],
            mutationFn: () => strapi.projectplanRegel.delete(projectplanRegel.id),
            onSuccess: async () => {
                addNotification({
                    id: 'deleteProjectplanRegel',
                    message: t('success', {
                        naam: projectplanRegel.attributes.naam,
                    }),
                    severity: 'success',
                    autoHideDuration: 5000,
                })

                removeProjectplanRegelFromState(projectplanRegel.id)
            },
            onError: () => {
                addNotification({
                    id: 'deleteProjectplanRegel',
                    message: t('error', {
                        naam: projectplanRegel.attributes.naam,
                    }),
                    severity: 'error',
                    autoHideDuration: 5000,
                })
            },
        }
    )

    return {
        deleteProjectplanRegel: async () => {
            await mutate()
        },
        isDeleteProjectplanRegelLoading,
    }
}