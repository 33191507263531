import {gql} from "@apollo/client";


export const CUSTOMER_TOKEN = gql`
    mutation customerToken($email: String!, $type: ENUM_USERSPERMISSIONSUSER_TYPE!, $storeViewId: String!) {
        customerToken(email: $email, type: $type, storeViewId: $storeViewId) {
            token
        }
    }
`
