import {apolloClient} from '../../../../providers/AppContext'
import {IProjectplan, Link, ProjectplanStatus} from '../../../../types/projectplan'
import {
    PROJECTPLAN_ADD_TO_CART,
    PROJECTPLAN_BY_SLUG,
    PROJECTPLAN_BY_SLUG_NO_MAGENTO_DATA,
    PROJECTPLAN_DELETE, PROJECTPLAN_LINK_DELETE, PROJECTPLAN_LINK_UPSERT,
    PROJECTPLAN_NEW, PROJECTPLAN_OPMERKING_ADD,
    PROJECTPLAN_REGEL_ADD,
    PROJECTPLAN_REGEL_DELETE,
    PROJECTPLAN_REGEL_UPDATE,
    PROJECTPLAN_REQUEST_CHECK,
    PROJECTPLAN_UPDATE,
    PROJECTPLANNEN_ALL,
    PROJECTPLANNEN_FILTERED
} from '../queries/projectplannen'
import {IProjectplanRegel} from '../../../../types/projectplan-regel'

export interface getProjectplannenApiResponse {
    projectplans: { data: IProjectplan[] }
}

export interface GetProjectplannenOptions {
    filters?: {
        status?: ProjectplanStatus
    }
}

export const getProjectplannen = async (options?: GetProjectplannenOptions) => {
    return apolloClient.query<getProjectplannenApiResponse>({
        query: options?.filters?.status ? PROJECTPLANNEN_FILTERED : PROJECTPLANNEN_ALL,
        variables: {
            ...options?.filters || {},
        },
        fetchPolicy: 'network-only',
    })
}

export interface getProjectplanBySlugApiResponse {
    projectplanBySlug: IProjectplan
}

export const getProjectplanBySlug = async (slug: string, includeMagentoData: boolean) => {
    return apolloClient.query<getProjectplanBySlugApiResponse>({
        query: includeMagentoData ? PROJECTPLAN_BY_SLUG : PROJECTPLAN_BY_SLUG_NO_MAGENTO_DATA,
        variables: {
            slug: slug,
        },
        fetchPolicy: 'network-only',
    })
}


export interface CreateProjectplanApiResponse {
    createProjectplan: { data: IProjectplan }
}

export const createProjectplan = async (naam: string) => {
    return apolloClient.mutate<CreateProjectplanApiResponse>({
        mutation: PROJECTPLAN_NEW,
        variables: {
            naam: naam,
        }
    })
}

export interface DeleteProjectplanApiResponse {
    deleteProjectplan: { data: IProjectplan }
}

export const deleteProjectplan = async (id: string) => {
    return apolloClient.mutate<DeleteProjectplanApiResponse>({
        mutation: PROJECTPLAN_DELETE,
        variables: {
            id,
        }
    })
}

export interface UpdateProjectplanApiResponse {
    updateProjectplan: { data: IProjectplan }
}

export const updateProjectplan = async ({id, naam, projectplanRegels}: {
    id: string,
    naam?: string,
    projectplanRegels?: IProjectplanRegel[] | { data: IProjectplanRegel[] }
}) => {
    if (!id) {
        throw new Error('No id provided')
    }

    if (projectplanRegels && !Array.isArray(projectplanRegels)) {
        throw new Error('Projectplan regels is not an array')
    }

    return apolloClient.mutate<UpdateProjectplanApiResponse>({
        mutation: PROJECTPLAN_UPDATE,
        variables: {
            id,
            naam,
            projectplanRegels: projectplanRegels ? projectplanRegels.map(regel => regel.id) : projectplanRegels,
        }
    })
}

export interface DeleteProjectplanRegelApiResponse {
    deleteProjectplanregel: { data: IProjectplanRegel }
}

export const deleteProjectplanRegel = async (id: string) => {
    return apolloClient.mutate<DeleteProjectplanRegelApiResponse>({
        mutation: PROJECTPLAN_REGEL_DELETE,
        variables: {
            id,
        }
    })
}

export interface UpdateProjectplanRegelApiResponse {
    updateProjectplanregel: { data: IProjectplanRegel }
}

export const updateProjectplanRegel = async ({id, aantal, productgroep}: {
    id: string,
    aantal?: number,
    productgroep?: string
}) => {
    return apolloClient.mutate<UpdateProjectplanRegelApiResponse>({
        mutation: PROJECTPLAN_REGEL_UPDATE,
        variables: {
            id,
            aantal,
            productgroep,
        }
    })
}

export interface AddProjectplanRegelToProjectplanApiResponse {
    addProjectplanRegelToProjectplan: {
        projectplan: IProjectplan,
        projectplanRegel: IProjectplanRegel,
    }
}

export const addProjectplanRegelToProjectplan = async ({projectplanId, sku, aantal}: {
    projectplanId: string,
    sku: string,
    aantal: number
}) => {
    return apolloClient.mutate<AddProjectplanRegelToProjectplanApiResponse>({
        mutation: PROJECTPLAN_REGEL_ADD,
        variables: {
            projectplanId,
            sku,
            aantal,
        }
    })
}

export interface ProjectplanAddToCartApiResponse {
    projectplanAddToCart: {
        projectplan: IProjectplan,
    }
}

export const addProjectplanToCart = async (projectplanId: string) => {
    return apolloClient.mutate<ProjectplanAddToCartApiResponse>({
        mutation: PROJECTPLAN_ADD_TO_CART,
        variables: {
            id: projectplanId,
        }
    })
}

export const requestProjectplanCheck = async (projectplanId: string) => {
    type ProjectplanCheckApiResponse = {
        projectplanRequestCheck: {
            data: IProjectplan,
        }
    }

    return apolloClient.mutate<ProjectplanCheckApiResponse>({
        mutation: PROJECTPLAN_REQUEST_CHECK,
        variables: {
            id: projectplanId,
        }
    })
}

export interface ProjectplanAddOpmerkingApiResponse {
    addOpmerkingToProjectplan: {
        data: IProjectplan,
    }
}

export const addOpmerkingToProjectplan = async (projectplanId: string, opmerking: string) => {
    return apolloClient.mutate<ProjectplanAddOpmerkingApiResponse>({
        mutation: PROJECTPLAN_OPMERKING_ADD,
        variables: {
            projectplanId,
            opmerking,
        }
    })
}

export interface ProjectplanLinkUpsertApiResponse {
    upsertProjectplanLink: Link
}

export interface ProjectplanLinkUpsertInput {
    projectplanId: string,
    url: string,
    linkId?: string|null,
}

export const upsertLinkToProjectplan = async ({projectplanId, linkId, url}: ProjectplanLinkUpsertInput) => {
    return apolloClient.mutate<ProjectplanLinkUpsertApiResponse>({
        mutation: PROJECTPLAN_LINK_UPSERT,
        variables: {
            projectplanId,
            linkId,
            url,
        }
    })
}


export interface ProjectplanLinkDeleteApiResponse {
    deleteProjectplanLink: Link
}

export interface ProjectplanLinkDeleteInput {
    projectplanId: string,
    linkId: string,
}

export const deleteLinkToProjectplan = async ({projectplanId, linkId}: ProjectplanLinkDeleteInput) => {
    return apolloClient.mutate<ProjectplanLinkDeleteApiResponse>({
        mutation: PROJECTPLAN_LINK_DELETE,
        variables: {
            projectplanId,
            linkId,
        }
    })
}
