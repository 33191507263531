import { getContent } from "./graphql/controller/contentController";
import {
    addProjectplanRegelToProjectplan,
    addProjectplanToCart,
    createProjectplan, deleteLinkToProjectplan,
    deleteProjectplan,
    deleteProjectplanRegel,
    getProjectplanBySlug,
    getProjectplannen,
    requestProjectplanCheck,
    updateProjectplan,
    updateProjectplanRegel, upsertLinkToProjectplan
} from './graphql/controller/projectplannenController'
import {generateCustomerToken} from "./graphql/controller/authController";
import {deleteFile, uploadMultiple} from "./graphql/controller/uploadController";


export default  {
    auth: {
        generateToken: generateCustomerToken,
    },
    content: {
        findMany: getContent,
    },
    upload: {
        uploadMultiple,
        delete: deleteFile,
    },
    projectplan: {
        findBySlug: getProjectplanBySlug,
        create: createProjectplan,
        delete: deleteProjectplan,
        findMany: getProjectplannen,
        update: updateProjectplan,
        addToCart: addProjectplanToCart,
        requestCheck: requestProjectplanCheck,
        upsertLink: upsertLinkToProjectplan,
        deleteLink: deleteLinkToProjectplan,
    },
    projectplanRegel: {
        delete: deleteProjectplanRegel,
        update: updateProjectplanRegel,
        addToProjectplan: addProjectplanRegelToProjectplan,
    }
}