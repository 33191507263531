import {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useProjectplannen} from "./useProjectplannen";
import {strapi} from "../../lib";
import {useNotifications} from "../notifications/useNotifications";
import {useTranslation} from "react-i18next";
import {IProjectplan, ProjectplanStatus} from "../../types/projectplan";

type UseAddToProjectplanOptions = {
    sku: string
    qtyEl: HTMLInputElement|null
}
export const useAddToProjectplan = ({sku, qtyEl}: UseAddToProjectplanOptions) => {
    const {t} = useTranslation('translation', {keyPrefix: 'add-to-projectplan'})
    const [qty, setQty] = useState(qtyEl?.value ? Number(qtyEl.value) : 1)
    const {data: projectplannen} = useProjectplannen({
        filters: {
            status: ProjectplanStatus.OPEN,
        }
    })
    const queryClient = useQueryClient()
    const {addNotification} = useNotifications()
    const {mutate, isPending: isLoading} = useMutation(
        {
            mutationKey: ['add-to-projectplan'],
            mutationFn: strapi.projectplanRegel.addToProjectplan,
            onSuccess: async (data) => {
                addNotification({
                    id: 'add-to-projectplan',
                    severity: 'success',
                    message: t('success', {
                        product: data.data?.addProjectplanRegelToProjectplan?.projectplanRegel?.attributes?.naam,
                        projectplan: data.data?.addProjectplanRegelToProjectplan.projectplan.attributes.naam
                    }),
                    autoHideDuration: 5000,
                })
                await queryClient.invalidateQueries({
                    queryKey: ['projectplannen']
                })
            },
            onError: () => {
                addNotification({
                    id: 'add-to-projectplan-error',
                    severity: 'error',
                    message: 'Er is iets misgegaan bij het toevoegen aan het projectplan',
                    autoHideDuration: 5000,
                })
            }
        },
    )

    useEffect(() => {
        if (!qtyEl) {
            return
        }

        const handleQtyChange = () => {
            setQty(Number(qtyEl.value))
        }
        qtyEl.addEventListener('change', handleQtyChange)
        return () => {
            qtyEl.removeEventListener('change', handleQtyChange)
        }
    }, [qtyEl])

    const addToProjectplan = (projectplan: IProjectplan) => {
        if (isLoading) {
            return
        }

        mutate({
            projectplanId: projectplan.id,
            sku,
            aantal: qty,
        })
    }

    return {
        projectplannen,
        addToProjectplan,
        isLoading,
    }
}