import {useQuery} from "@tanstack/react-query";
import strapi from "../../lib/strapi";
import {GetProjectplannenOptions} from "../../lib/strapi/graphql/controller/projectplannenController";


export const useProjectplannen = (options?: GetProjectplannenOptions) => {
    const {data, isLoading, error} = useQuery(
        {
            queryKey: ['projectplannen', options],
            queryFn: () => strapi.projectplan.findMany(options),
        },
    )

    return {
        data: data?.data?.projectplans?.data || [],
        isLoading,
        error,
    }
}