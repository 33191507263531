import React from 'react';
import {HasChildren} from "../types/components";
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client'
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {createUploadLink} from "apollo-upload-client";
import {setContext} from "@apollo/client/link/context";
import {createTheme, ThemeProvider} from '@mui/material';
import utils from "../lib/utils";
import config from "../config";
import {LangProvider} from "../lang/resources";
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import {useNotificationsContext, useSessionContext} from "../hooks";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {IMagentoUser} from "../types/magento";


// Initialize GraphQl Provider
const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = utils.localStorage.get('user') ? utils.localStorage.get('token') : null
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
})
const uploadLink = createUploadLink({
    uri: `${config.api.uri}/graphql`,
})
const batchHttpLink = new BatchHttpLink({
    uri: `${config.api.uri}/graphql`,
    batchMax: 1,
    batchInterval: 5,
})
export const apolloClient = new ApolloClient({
    link: authLink.concat(uploadLink).concat(batchHttpLink),
    cache: new InMemoryCache(),
})

export const apolloClientNoAuth = new ApolloClient({
    link: uploadLink.concat(batchHttpLink),
    cache: new InMemoryCache(),
})

const ApolloGraphQlProvider: React.FC<HasChildren> = ({children}) => {
    return (
        <ApolloProvider client={apolloClient}>
            {children}
        </ApolloProvider>
    )
}


const theme = createTheme({
    palette: {
        primary: {
            main: '#EE7D16',
            light: '#EE7D16',
            dark: '#EE7D16',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#E9548E',
            light: '#E9548E',
            dark: '#E9548E',
            contrastText: '#FFFFFF',
        },
    }
});

const queryClient = new QueryClient()

export interface AppContextProps extends HasChildren {

}

export interface AppContextProps extends HasChildren {
    user: IMagentoUser | null
}

const AppContext: React.FC<AppContextProps> = ({children, user}) => {
    const {NotificationsContext, values} = useNotificationsContext()
    const {SessionContext, values: sessionContextValues} = useSessionContext(user)

    return (
        <QueryClientProvider client={queryClient}>
            <ApolloGraphQlProvider>
                <ThemeProvider theme={theme}>
                    <LangProvider>
                        <NotificationsContext.Provider value={values}>
                            <SessionContext.Provider value={sessionContextValues}>
                                {children}
                                {process.env.NODE_ENV === 'development' && (
                                    <ReactQueryDevtools initialIsOpen={false} buttonPosition={'bottom-left'}/>
                                )}
                            </SessionContext.Provider>
                        </NotificationsContext.Provider>
                    </LangProvider>
                </ThemeProvider>
            </ApolloGraphQlProvider>
        </QueryClientProvider>
    )
}

export default AppContext


