import {IProjectplan} from "../../types/projectplan";
import {useTranslation} from "react-i18next";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useNotifications} from "../notifications/useNotifications";
import {strapi} from "../../lib";

export const useProjectplanDelete = (projectplan: IProjectplan) => {
    const {t} = useTranslation('translation', {keyPrefix: 'projectplannen.delete'})
    const client = useQueryClient()
    const {addNotification} = useNotifications()
    const {mutate, isPending: isDeleteLoading} = useMutation({
        mutationKey: ['deleteProjectplan'],
        mutationFn: () => strapi.projectplan.delete(projectplan.id),
        onSuccess: () => {
            addNotification({
                id: 'projectplan.delete.success',
                message: t('success', {
                    naam: projectplan?.attributes?.naam || '',
                }),
                severity: 'success',
                autoHideDuration: 5000,
            })
            client.setQueryData(['projectplannen'], (oldData: any) => ({
                ...oldData,
                data: {
                    ...oldData.data,
                    projectplans: {
                        data: oldData.data.projectplans.data.filter((plan: IProjectplan) => plan.id !== projectplan.id)
                    }
                }
            }))
        }
    })

    return {
        deleteProjectplan: mutate,
        isDeleteLoading,
    }
}

