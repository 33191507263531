
export const setLocalStorage = (key: string, value: any) => {
    if (!localStorageIsDefined()) {
        return
    }

    if (typeof value !== 'string') {
        value = JSON.stringify(value)
    }

    localStorage.setItem(key, value)
}

export const getFromLocalStorage = (key: string) => {
    if (!localStorageIsDefined()) {
        return null
    }

    const value = localStorage.getItem(key)

    if (value === null) {
        return null
    }

    try {
        return JSON.parse(value)
    } catch (e) {
        return value
    }
}

export const removeFromLocalStorage = (key: string) => {
    if (!localStorageIsDefined()) {
        return
    }

    localStorage.removeItem(key)
}

export const hasInLocalStorage = (key: string) => {
    if (typeof localStorage === 'undefined') {
        return false
    }

    return localStorage.hasOwnProperty(key)
}

export const localStorageIsDefined = () => {
    return typeof localStorage !== 'undefined'
}
