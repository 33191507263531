import {gql} from "@apollo/client";

export const UPLOAD_FILES = gql`
    mutation uploadFiles($refId: ID, $ref: String, $field: String, $files: [Upload]!) {
        multipleUpload(refId: $refId, ref: $ref, field: $field, files: $files) {
            data {
                id
                attributes {
                    name
                    url
                    mime
                    alternativeText
                    size
                }
            }
        }
    }
`

export const DELETE_FILE = gql`
    mutation deleteUpload($id: ID!) {
        deleteUploadFile(id: $id) {
            data {
                id
            }
        }
    }
`