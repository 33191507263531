import {getFromLocalStorage, hasInLocalStorage, localStorageIsDefined, removeFromLocalStorage, setLocalStorage} from "./localStorage";
import {delay} from "./misc";

import * as react from './react'
import * as object from './object'

export const utils = {
    localStorage: {
        get: getFromLocalStorage,
        set: setLocalStorage,
        has: hasInLocalStorage,
        remove: removeFromLocalStorage,
        isDefined: localStorageIsDefined,
    },
    misc: {
        delay,
    },
    react,
    object,
}

export default utils