
// @ts-ignore
export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean)
    if (!filteredRefs.length) return null
    if (filteredRefs.length === 0) return filteredRefs[0] // @ts-ignore
    return inst => {
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') {
                ref(inst)
            } else if (ref) {
                ref.current = inst
            }
        }
    }
}