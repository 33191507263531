import React, {memo} from 'react';
import {Alert, AlertColor, Slide, SlideProps, Snackbar as MuiSnackbar} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {HasOptionalChildren} from "../../types/components";
import {SnackbarOrigin} from "@mui/material/Snackbar/Snackbar";

export interface SnackbarProps extends HasOptionalChildren {
    autoHideDuration: number
    open: boolean
    severity?: AlertColor
    transition: React.ComponentType<TransitionProps & {
        children: React.ReactElement<any, any>;
    }>
    close: () => void
    anchorOrigin?: SnackbarOrigin
}

export function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up"/>;
}

const Snackbar: React.FC<SnackbarProps> = memo(({autoHideDuration, open, transition, close, children, severity = 'success', anchorOrigin}) => {


    return (
        <MuiSnackbar
            open={open}
            onClose={close}
            TransitionComponent={transition}
            key={transition.name}
            autoHideDuration={autoHideDuration}
            anchorOrigin={anchorOrigin}
        >
            <Alert severity={severity} sx={{ width: '100%', fontSize: '15px' }}>
                {children}
            </Alert>
        </MuiSnackbar>
    )
})

export default Snackbar