import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {strapi} from "../../lib";


export const useNewProjectplan = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [error, setError] = useState<{ naam: string | null }>({naam: null})
    const {mutate, isPending: isLoading} = useMutation({
        mutationKey: ['newProjectplan'],
        mutationFn: strapi.projectplan.create,
        onSuccess: async data => {
            queryClient.setQueryData(['projectplannen'], (oldData: any) => {
                return {
                    ...oldData,
                    data: {
                        ...oldData?.data || {},
                        projectplans: {
                            data: [
                                ...oldData?.data?.projectplans?.data || [],
                                data.data?.createProjectplan.data,
                            ]
                        }
                    }
                }
            })

            navigate(t('navigation.routes.projectplannen-detail', {
                id: data.data?.createProjectplan.data.attributes.slug,
                prefix: window.getBaseUrl() ? `/${window.getBaseUrl()}` : '',
            }))
        },
    })

    const createNewProjectplan = async (e: React.FormEvent) => {
        e.preventDefault()
        const naam = (new FormData(e.currentTarget as HTMLFormElement)).get('naam') as string

        if (naam.length < 1) {
            setError({
                ...error,
                naam: t('general.field-is-required', {field: t('projectplannen.new.naam')}),
            })
            return
        }

        setError({
            ...error,
            naam: null,
        })

        mutate(naam)
    }

    return {
        createNewProjectplan,
        isLoading,
        error,
    }
}