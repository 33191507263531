
export enum ApiType {
    WORDPRESS = 'WORDPRESS',
    JOOMLA = 'JOOMLA',
    STRAPI = 'STRAPI',
}

export type Config = {
    app: {
        lang: {
            htmlSlug: string
            uriSlug: string
            country: string
            lang: string
        },
    },
    api: {
        type: ApiType
        uri: string
        token?: string
    }
}
