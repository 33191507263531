import {IProjectplan} from "../../types/projectplan";
import {useProjectplanDelete} from "./useProjectplanDelete";


export const useProjectplanActions = (projectplan: IProjectplan) => {
    const {deleteProjectplan, isDeleteLoading} = useProjectplanDelete(projectplan)

    return {
        deleteProjectplan,
        isDeleteLoading,
    }
}

