import {ApiType, Config} from "./types/config";
import {API_TOKEN, API_URL} from "./constants";

const config: Config = {
    app: {
        lang: {
            htmlSlug: 'nl_NL',
            uriSlug: '',
            lang: 'nl',
            country: 'nl',
        }
    },
    api: {
        type: ApiType.STRAPI,
        uri: API_URL,
        token: API_TOKEN,
    },
}

export default config