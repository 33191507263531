import {gql} from "@apollo/client";


export const CONTENT = gql`
    query content {
        contents (pagination:{limit:999}){
            data {
                attributes {
                    naam
                    inhoud
                }
            }
        }
    }
`
