import {useMutation} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {IProjectplanRegel} from "../../types/projectplan-regel";
import {useProjectplan} from "../projectplan/useProjectplan";
import {strapi} from "../../lib";
import {useDebounce} from "../utils/useDebounce";

type ProjectplanRegelUpdate = Omit<Parameters<typeof strapi.projectplanRegel.update>[0], 'id'>

export const useProjectplanRegelUpdate = (projectplanRegel: IProjectplanRegel) => {
    const [aantalTmp, setAantal] = useState<number>(projectplanRegel.attributes.aantal)
    const aantal = useDebounce(aantalTmp, 500)
    const [prevAantal, setPrevAantal] = useState<number>(projectplanRegel.attributes.aantal)
    const {refetch} = useProjectplan()
    const {mutate, isPending: isUpdateProjectplanRegelLoading} = useMutation(
        {
            mutationKey: ['updateProjectplanRegel', projectplanRegel.id],
            mutationFn: async ({aantal, productgroep}: ProjectplanRegelUpdate) => {
                return strapi.projectplanRegel.update({
                    id: projectplanRegel.id,
                    aantal,
                    productgroep,
                })
            },
            onSuccess: () => {
                refetch()
            },
        }
    )

    const updateProjectplanRegel = (data: ProjectplanRegelUpdate) => {
        setAantal((prevState) => data?.aantal || prevState)
    }

    useEffect(() => {
        if (aantal === prevAantal) {
            return
        }

        setPrevAantal(() => aantal)
        mutate({
            aantal,
        })
    }, [aantal])

    return {
        updateProjectplanRegel: updateProjectplanRegel,
        isUpdateProjectplanRegelLoading,
    }
}
