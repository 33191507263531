import {apolloClient} from '../../../../providers/AppContext'
import {DELETE_FILE, UPLOAD_FILES} from "../queries/upload";
import {IStrapiImage} from "../../../../types/strapi";


export interface UploadFilesApiResponse {
    multipleUpload: {
        data: IStrapiImage,
    }[]
}

export interface UploadFilesInput {
    refId?: string
    ref?: 'projectplan',
    field?: string
    files: File[]
}

export const uploadMultiple = async ({refId, ref, field, files}: UploadFilesInput) => {
    return apolloClient.mutate<UploadFilesApiResponse>({
        mutation: UPLOAD_FILES,
        variables: {
            refId,
            ref: ref ? `api::${ref}.${ref}` : undefined,
            field,
            files,
        }
    })
}


export interface DeleteFilesApiResponse {
    deleteUploadFile: {
        data: IStrapiImage,
    }
}
export const deleteFile = async (id: string) => {
    return apolloClient.mutate<DeleteFilesApiResponse>({
        mutation: DELETE_FILE,
        variables: {
            id,
        }
    })
}